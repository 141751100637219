/** @format */

import type {ThemeConfig} from 'antd';
import {hexToRgba} from 'helpers';
import {createStyles} from 'antd-style';

export const COLORS = {
  title: '#566a7f',
  text: '#697a8d',
  white: '#ffffff',
  border: '#cccccc',
  primary: '#01A74E',
  background: '#f5f5f9',
  placeholder: '#B3B3B3',
  boxShadow: '0 6px 16px -8px rgba(0, 0, 0, 0.08)',
  shadowHeader: '0px 10px 40px -25px rgba(0,0,0,0.49)',
  btnGradient: 'linear-gradient(135deg, #31c48d, #0e9f6e, #008040)',
};

export const THEME_ANTD: ThemeConfig = {
  token: {
    colorPrimary: COLORS.primary,
    colorSuccess: COLORS.primary,
    colorLink: COLORS.primary,
    colorWarning: '#FFC000',
    colorError: '#FF1F1F',
    colorBorder: COLORS.border,
    colorBgLayout: COLORS.background,
    colorText: COLORS.text,
    colorTextBase: COLORS.text,
    colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
    colorTextSecondary: 'rgba(0, 0, 0, 0.45)',
    colorTextHeading: COLORS.title,
    colorTextPlaceholder: COLORS.placeholder,
  },
  components: {
    Layout: {
      siderBg: COLORS.white,
      headerBg: COLORS.background,
      headerPadding: 0,
      borderRadius: 12,
    },
    Menu: {
      itemBg: COLORS.white,
      itemColor: COLORS.text,
      itemSelectedColor: '#008040',
      itemHoverColor: '#008040',
      popupBg: COLORS.white,
      controlItemBgActive: hexToRgba(COLORS.primary, 0.15),
      itemActiveBg: hexToRgba(COLORS.primary, 0.15),
      itemHoverBg: hexToRgba(COLORS.primary, 0.15),
      itemSelectedBg: hexToRgba(COLORS.primary, 0.15),
      subMenuItemBg: COLORS.white,
      itemBorderRadius: 5,
      controlItemBgActiveHover: hexToRgba(COLORS.primary, 0.15),
      fontSize: 15,
      itemHeight: 45,
    },
    Table: {
      headerBorderRadius: 0,
      headerBg: hexToRgba(COLORS.border, 0.2),
    },
    Segmented: {
      trackBg: COLORS.background,
      itemActiveBg: COLORS.primary,
      itemSelectedBg: hexToRgba(COLORS.primary, 0.8),
      itemSelectedColor: COLORS.white,
    },
  },
};

export const useStyle = createStyles(({token, prefixCls, css}) => ({
  linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(.${prefixCls}-btn-dangerous) {
      border-width: 0;
      > span {
        position: relative;
      }
      &::before {
        content: '';
        background: linear-gradient(135deg, #31c48d, #0e9f6e, #008040);
        position: absolute;
        inset: 0;
        opacity: 0.8;
        transition: all 0.3s;
        border-radius: inherit;
      }
      &:hover::before {
        opacity: 0.5;
      }
    }
  `,
}));
